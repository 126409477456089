import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { isCSMHelpCenter } from 'util/advanced-help-center';
import { useIsoMorphicLayoutEffect } from 'util/hooks';
import useDebouncedValue from 'util/use-debounced-value';
import { isUnifiedHelpRequestFormReorderingEnabled } from 'feature-flags';
import { LazySuspense } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { usePathParam, useRouter } from 'react-resource-router';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getCloudId } from '@atlassian/help-center-common-util/meta';
import { Articles } from '../articles';
import type { articlesFragment$key } from '../articles/__generated__/articlesFragment.graphql';
import {
    ALL_RESULTS_COUNT,
    DEFAULT_RESULTS_COUNT,
    SEARCH_QUERY_DEBOUNCE_TIME,
    useQueryParams,
    getIdsArray,
    isSinglePortalSearch,
} from '../common';
import { csmSearchExperience } from '../experiences';
import { SearchResultsLoader } from '../loading';
import { SearchResultNone } from '../none';
import { Portals } from '../portals';
import type { portalsFragment$key } from '../portals/__generated__/portalsFragment.graphql';
import { RequestForms } from '../request-forms';
import type { requestFormsFragment$key } from '../request-forms/__generated__/requestFormsFragment.graphql';
import type { searchResultArticlesAndPortalsQuery } from './__generated__/searchResultArticlesAndPortalsQuery.graphql';
import type { searchResultCombinedQuery } from './__generated__/searchResultCombinedQuery.graphql';
import type { searchResultRequestFormQuery } from './__generated__/searchResultRequestFormQuery.graphql';

export interface ResourceProps {
    term: string;
    articlesData: articlesFragment$key;
    portalsData: portalsFragment$key;
    requestFormData: requestFormsFragment$key;
    showAllResults?: boolean;
    updateResultsCount: (count: number) => void;
}

export const Resources = ({
    term,
    articlesData,
    portalsData,
    requestFormData,
    showAllResults,
    updateResultsCount,
}: ResourceProps) => {
    di(getEnv);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    const [{ location }] = useRouter();
    const onlyRenderArticles = location?.pathname?.includes('/articles/search') || isCSM;
    const isRequestFormReorderingEnabled = isUnifiedHelpRequestFormReorderingEnabled();
    const renderArticles = () => (
        <Articles
            term={term}
            result={articlesData}
            showAllResults={showAllResults}
            updateResultsCount={updateResultsCount}
        />
    );
    const renderPortals = () => <Portals term={term} result={portalsData} updateResultsCount={updateResultsCount} />;
    const renderRequestForms = () => (
        <RequestForms term={term} result={requestFormData} updateResultsCount={updateResultsCount} />
    );

    if (onlyRenderArticles) {
        return renderArticles();
    }
    if (isRequestFormReorderingEnabled) {
        return (
            <>
                <LazySuspense fallback={<SearchResultsLoader numberOfSections={1} />}>{renderArticles()}</LazySuspense>
                <LazySuspense fallback={<SearchResultsLoader numberOfSections={1} />}>{renderPortals()}</LazySuspense>
                <LazySuspense fallback={<SearchResultsLoader numberOfSections={1} />}>
                    {renderRequestForms()}
                </LazySuspense>
            </>
        );
    }
    return (
        <>
            {renderArticles()}
            {renderRequestForms()}
            {renderPortals()}
        </>
    );
};

export interface Props {
    term: string;
    showAllResults?: boolean;
}

type SearchResultProps = Props & WithAnalyticsEventsProps;

function useParallelQuery(
    cloudId: string,
    queryTerm: string,
    portalIds: string[],
    categoryIds: string[],
    resultLimit: number
): {
    articlesFragment: articlesFragment$key;
    portalsFragment: portalsFragment$key;
    requestFormFragment: requestFormsFragment$key;
} {
    const ARTICLES_AND_PORTALS_QUERY = graphql`
        query searchResultArticlesAndPortalsQuery(
            $cloudId: ID!
            $queryTerm: String!
            $resultLimit: Int
            $portalIds: [ID!]
            $categoryIds: [ID!]
        ) {
            helpObjectStore(cloudId: $cloudId) @required(action: THROW) {
                __typename
                ...articlesFragment
                ...portalsFragment
            }
        }
    `;

    const REQUEST_FORM_QUERY = graphql`
        query searchResultRequestFormQuery($cloudId: ID!, $queryTerm: String!, $resultLimit: Int, $portalIds: [ID!]) {
            helpObjectStore(cloudId: $cloudId) @required(action: THROW) {
                __typename
                ...requestFormsFragment
            }
        }
    `;

    const articlesAndPortalsData = useLazyLoadQuery<searchResultArticlesAndPortalsQuery>(
        ARTICLES_AND_PORTALS_QUERY,
        {
            cloudId,
            queryTerm,
            portalIds,
            categoryIds,
            resultLimit,
        },
        { fetchPolicy: 'store-or-network' }
    );

    const requestFormData = useLazyLoadQuery<searchResultRequestFormQuery>(
        REQUEST_FORM_QUERY,
        {
            cloudId,
            queryTerm,
            portalIds,
            resultLimit,
        },
        { fetchPolicy: 'store-or-network' }
    );

    return {
        articlesFragment: articlesAndPortalsData.helpObjectStore,
        portalsFragment: articlesAndPortalsData.helpObjectStore,
        requestFormFragment: requestFormData.helpObjectStore,
    };
}

function useCombinedQuery(
    cloudId: string,
    queryTerm: string,
    portalIds: string[],
    categoryIds: string[],
    resultLimit: number
): {
    articlesFragment: articlesFragment$key;
    portalsFragment: portalsFragment$key;
    requestFormFragment: requestFormsFragment$key;
} {
    const COMBINED_SEARCH_QUERY = graphql`
        query searchResultCombinedQuery(
            $cloudId: ID!
            $queryTerm: String!
            $resultLimit: Int
            $portalIds: [ID!]
            $categoryIds: [ID!]
        ) {
            helpObjectStore(cloudId: $cloudId) @required(action: THROW) {
                __typename
                ...requestFormsFragment
                ...articlesFragment
                ...portalsFragment
            }
        }
    `;

    const combinedData = useLazyLoadQuery<searchResultCombinedQuery>(
        COMBINED_SEARCH_QUERY,
        {
            cloudId,
            queryTerm,
            portalIds,
            categoryIds,
            resultLimit,
        },
        { fetchPolicy: 'store-or-network' }
    );
    return {
        articlesFragment: combinedData.helpObjectStore,
        portalsFragment: combinedData.helpObjectStore,
        requestFormFragment: combinedData.helpObjectStore,
    };
}

interface SearchParameters {
    cloudId: string;
    portalId?: string;
    portalIds: string[];
    categoryIds: string[];
    resultLimit: number;
    getSelectedPortalIds: () => string[];
}

const useExtractSearchParameters = ({
    showAllResults,
    CSMPortalId,
}: {
    showAllResults?: boolean;
    CSMPortalId: number | undefined;
}): SearchParameters => {
    const cloudId = useMemo(() => getCloudId(), []);
    const {
        portalIds,
        categoryIds,
        resultLimit = DEFAULT_RESULTS_COUNT,
    } = useQueryParams() as {
        portalIds: string[];
        categoryIds: string[];
        resultLimit?: number;
    };
    const [portalId] = usePathParam('portalId');
    const getSelectedPortalIds = () => {
        if (CSMPortalId) return getIdsArray(CSMPortalId);
        if (isSinglePortalSearch(portalId)) return getIdsArray(portalId);
        return getIdsArray(portalIds);
    };

    return {
        cloudId,
        portalIds,
        categoryIds,
        portalId,
        getSelectedPortalIds,
        resultLimit: showAllResults ? ALL_RESULTS_COUNT : resultLimit,
    };
};

interface ResourceWrapperProps {
    term: string;
    showAllResults?: boolean;
    updateResultsCount: (count: number) => void;
    searchParams: SearchParameters;
}

const ParallelQueryWrapperOnResource = ({
    term,
    updateResultsCount,
    showAllResults,
    searchParams,
}: ResourceWrapperProps) => {
    const { articlesFragment, portalsFragment, requestFormFragment } = useParallelQuery(
        searchParams.cloudId,
        term,
        searchParams.getSelectedPortalIds(),
        searchParams.categoryIds,
        searchParams.resultLimit
    );
    return (
        <Resources
            term={term}
            articlesData={articlesFragment}
            portalsData={portalsFragment}
            requestFormData={requestFormFragment}
            showAllResults={showAllResults}
            updateResultsCount={updateResultsCount}
        />
    );
};

const CombinedQueryWrapperOnResource = ({
    term,
    updateResultsCount,
    showAllResults,
    searchParams,
}: ResourceWrapperProps) => {
    const { articlesFragment, portalsFragment, requestFormFragment } = useCombinedQuery(
        searchParams.cloudId,
        term,
        searchParams.getSelectedPortalIds(),
        searchParams.categoryIds,
        searchParams.resultLimit
    );
    return (
        <Resources
            term={term}
            articlesData={articlesFragment}
            portalsData={portalsFragment}
            requestFormData={requestFormFragment}
            showAllResults={showAllResults}
            updateResultsCount={updateResultsCount}
        />
    );
};

export const SearchResult = ({ term, showAllResults }: SearchResultProps) => {
    di(getEnv);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    const CSMPortalId = isCSM ? getEnv().hoistedPortalId : undefined;
    const searchParams = useExtractSearchParameters({ showAllResults, CSMPortalId });
    const [totalCount, setTotalCount] = useState(-1);
    const resultsCount = useRef(-1);
    const debouncedTerm = useDebouncedValue(term, SEARCH_QUERY_DEBOUNCE_TIME);
    const isRequestFormReorderingEnabled = isUnifiedHelpRequestFormReorderingEnabled();
    const numberOfSections = isCSM ? 1 : 3;

    const updateResultsCount = useCallback((count: number) => {
        if (resultsCount.current < 0) resultsCount.current = 0;
        resultsCount.current += count;
        setTotalCount(resultsCount.current);
    }, []);

    useIsoMorphicLayoutEffect(() => {
        // Reset count to initial state on term change
        resultsCount.current = -1;
        setTotalCount(-1);
    }, [debouncedTerm]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        isCSM && csmSearchExperience.start();

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            isCSM && csmSearchExperience.abort();
        };
    }, [debouncedTerm, isCSM]);

    const isNoResults = totalCount === 0;
    return (
        <LazySuspense fallback={<SearchResultsLoader numberOfSections={numberOfSections} />}>
            {isNoResults && <SearchResultNone term={debouncedTerm} />}
            {isRequestFormReorderingEnabled && !isNoResults && (
                <ParallelQueryWrapperOnResource
                    term={debouncedTerm}
                    searchParams={searchParams}
                    showAllResults={showAllResults}
                    updateResultsCount={updateResultsCount}
                />
            )}
            {!isRequestFormReorderingEnabled && !isNoResults && (
                <CombinedQueryWrapperOnResource
                    term={debouncedTerm}
                    searchParams={searchParams}
                    showAllResults={showAllResults}
                    updateResultsCount={updateResultsCount}
                />
            )}
        </LazySuspense>
    );
};
