import type { Reducer } from 'react';
import { useEffect, useReducer } from 'react';
import { getJsmAIConfigEnabled } from '@helpCenter/util/env';
import { isJsmVirtualAgentOnHelpcenterEnabled, isVirtualAgentAdminToggleEnabled } from 'feature-flags';
import { di } from 'react-magnetic-di';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { fetchJson } from '@atlassian/help-center-common-util/fetch';
import { getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import type { helpCenterVirtualAgentAvailabilityQuery } from './__generated__/helpCenterVirtualAgentAvailabilityQuery.graphql';
import type { Action, State } from './types';

// This should be cleand up once we rollout jsm-va-on-hc-admin-toggle feature flag, we could move the query to resource
const initialState: State = {
    isVAOnHCAvailable: false,
    isLoading: true,
};

const reducer: Reducer<State, Action> = (state, action) => {
    switch (action.type) {
        case 'RESET': {
            return {
                isVAOnHCAvailable: false,
                isLoading: false,
            };
        }
        case 'SET_AVAILABILITY': {
            return {
                isVAOnHCAvailable: action.isVAOnHCAvailable,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};

interface VirtualAgentFeaturesResponse {
    isVirtualAgentAvailable: boolean;
    isAiEnabledInAdminHub: boolean;
}

export const useVirtualAgentOnHelpCenterAvailability = ({ cloudId }: { cloudId: string }) => {
    di(fetchJson, useAnalyticsEvents, getJsmAIConfigEnabled);
    const [{ isVAOnHCAvailable, isLoading }, dispatch] = useReducer(reducer, initialState);
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const isJsmAIConfigEnabled = getJsmAIConfigEnabled();

    const data = useLazyLoadQuery<helpCenterVirtualAgentAvailabilityQuery>(
        graphql`
            query helpCenterVirtualAgentAvailabilityQuery(
                $cloudId: ID!
                $helpCenterAri: ID!
                $isVirtualAgentAdminToggleEnabled: Boolean!
            ) {
                helpCenter(cloudId: $cloudId) @include(if: $isVirtualAgentAdminToggleEnabled) {
                    helpCenterById(helpCenterAri: $helpCenterAri) @optIn(to: "HelpCenterAggBeta") {
                        __typename
                        ... on HelpCenter {
                            virtualAgentEnabled
                        }
                        ... on QueryError {
                            message
                        }
                    }
                }
            }
        `,
        {
            cloudId,
            helpCenterAri: getHelpCenterAri(),
            isVirtualAgentAdminToggleEnabled: isVirtualAgentAdminToggleEnabled(),
        }
    );

    useEffect(() => {
        if (!isJsmVirtualAgentOnHelpcenterEnabled() || !isJsmAIConfigEnabled) {
            dispatch({ type: 'RESET' });
            return;
        }

        if (isVirtualAgentAdminToggleEnabled()) {
            if (data?.helpCenter?.helpCenterById?.__typename === 'HelpCenter') {
                dispatch({
                    type: 'SET_AVAILABILITY',
                    isVAOnHCAvailable: data?.helpCenter?.helpCenterById?.virtualAgentEnabled || false,
                });
                return;
            }
            dispatch({
                type: 'RESET',
            });
            return;
        }

        fetchJson<VirtualAgentFeaturesResponse>(
            `/gateway/api/jsm/virtual-agent/cloudId/${encodeURIComponent(cloudId)}/public/api/v1/va-features`,
            { headers: { 'Content-Type': 'application/json' } }
        )
            .then((response: VirtualAgentFeaturesResponse) => {
                dispatch({
                    type: 'SET_AVAILABILITY',
                    isVAOnHCAvailable: response.isVirtualAgentAvailable && response.isAiEnabledInAdminHub,
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: 'SET_AVAILABILITY',
                    isVAOnHCAvailable: false,
                });

                if ('status' in err && typeof err.status === 'number' && err.status === 403) {
                    return;
                }

                createAnalyticsEvent({
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    action: 'failed',
                    actionSubject: 'virtualAgentOnHelpCenterAvailability',
                    attributes: {
                        error: err.message,
                    },
                }).fire();
            });
    }, [createAnalyticsEvent, cloudId, isJsmAIConfigEnabled, data]);

    return {
        isVAOnHCAvailable,
        isLoading,
    };
};
