import React from 'react';
import { compose } from 'redux';
import {
    isJsmVirtualAgentOnHelpcenterEnabled,
    isUnifiedHelpInJSMEnabled,
    isVAOnHelpCenterTestConversationEnabled,
    isUnifiedHelpAddFilterForCustomerAccounts,
} from 'feature-flags';
import { useIntl } from 'react-intl-next';
import type { RouteContext } from 'react-resource-router';
import { useQueryParam } from 'react-resource-router';
import { ConversationPage, authPageMessage, callToActionUrlText, ErrorPage, Layout } from 'view/unified-help-center';
import { CONVERSATION_PATH, ScreenName, VA_CONVERSATION_PATH } from '@atlassian/help-center-common-component/constants';
import { withJsmAiConfigEnabled } from '@atlassian/help-center-common-component/with-jsm-ai-config';
import withOnlyAtlassianAccount from '@atlassian/help-center-common-component/with-only-atlassian-account';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import { withOnlyProjectAdmin } from '@atlassian/help-center-common-component/with-only-project-admin';

const Error = () => <ErrorPage />;

const WrappedConversationPage = () => (
    <Layout
        renderError={Error}
        component={ConversationPage}
        screenName={ScreenName.CONVERSATION_PAGE}
        headerless
        footerless
    />
);

export const ConversationHomePage = () => {
    const WrappedComponent = isUnifiedHelpAddFilterForCustomerAccounts()
        ? compose(withOnlyLoggedIn({ redirectUrl: '/' }))(() => <WrappedConversationPage />)
        : compose(
              withOnlyLoggedIn({ redirectUrl: '/' }),
              withOnlyAtlassianAccount()
          )(() => <WrappedConversationPage />);
    return <WrappedComponent />;
};

export const VirtualAgentConversationHomePage = (props: RouteContext) => {
    const { formatMessage } = useIntl();
    const [isTestConversationParam, setIsTestConversationParam] = useQueryParam('isTestConversation');
    let enhancer = compose(
        withOnlyLoggedIn({ redirectUrl: '/' }),
        withJsmAiConfigEnabled({
            message: formatMessage(authPageMessage),
            callToActionText: formatMessage(callToActionUrlText),
            callToActionUrl: '/portals',
        })
    );
    const portalIdAsString = props.match.params && props.match.params.portalId;

    if (!portalIdAsString && !isVAOnHelpCenterTestConversationEnabled()) {
        setIsTestConversationParam(undefined);
    }

    if (isTestConversationParam === 'true') {
        const withPermissionCheck = portalIdAsString ? withOnlyProjectAdmin : withOnlyJiraAdmin;
        enhancer = compose(
            withOnlyLoggedIn({ redirectUrl: '/' }),
            withJsmAiConfigEnabled({
                message: formatMessage(authPageMessage),
                callToActionText: formatMessage(callToActionUrlText),
                callToActionUrl: '/portals',
            }),
            withPermissionCheck({
                message: formatMessage(authPageMessage),
                callToActionText: formatMessage(callToActionUrlText),
                callToActionUrl: portalIdAsString ? `/portal/${portalIdAsString}` : '/portals',
            })
        );
    }

    const WrappedComponent = enhancer(() => <WrappedConversationPage />);
    return <WrappedComponent {...props} />;
};
export const getConversationPageRoute = () => {
    const viewConversationPageRoute = {
        name: ScreenName.CONVERSATION_PAGE,
        path: CONVERSATION_PATH,
        exact: true,
        component: ConversationHomePage,
    };

    if (isJsmVirtualAgentOnHelpcenterEnabled()) {
        return [
            {
                ...viewConversationPageRoute,
                component: VirtualAgentConversationHomePage,
            },
        ];
    }
    return isUnifiedHelpInJSMEnabled() ? [viewConversationPageRoute] : [];
};

export const viewVirtualAgentConversationPageRoute = {
    name: ScreenName.CONVERSATION_PAGE,
    path: VA_CONVERSATION_PATH,
    exact: true,
    component: VirtualAgentConversationHomePage,
};
