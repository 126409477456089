import type { KeyboardEvent, ReactElement } from 'react';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@compiled/react';
import type { LogOutAction } from '@helpCenter/epics/logout';
import { isAdvancedHelpCenter } from '@helpCenter/util/advanced-help-center';
import { getIsHCCustomizationEnabled, getIsMultiHCEnabled } from '@helpCenter/util/env';
import { getCloudId, getWorkspaceAri } from '@helpCenter/util/meta';
import { clearSidebarExperienceAndCustomize } from '@helpCenter/util/sidebar-experience';
import { getBillingUrl } from '@helpCenter/util/url';
import { ThemedBadge as AvatarBadge } from '@helpCenter/view/common/badges';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { ForgeActionModule } from '@helpCenter/view/forge-ui/forge-action';
import { useQueryParams } from '@helpCenter/view/search/common';
import {
    isWrmOptimizationsEnabled,
    isUnifiedHelpReportingPageEnabled,
    isAdvancedCustomizationEnabled,
    isChangeBoardingForJSMAdminsFreeAndStandardEditionEnabled,
    isChangeBoardingForJSMAdminsSinglePortalEnabled,
    isChangeBoardingForJSMAdminsPremiumAndEnterpriseEditionEnabled,
    isJsmCsMasterFlagEnabled,
    isNewReportingExperienceEnabled,
    isAdvancedCustomizationEditPageEnabled,
    isHelpCenterNewDropdownEnabled,
    isVirtualAgentAdminToggleEnabled,
} from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import type { PreloadedQuery } from 'react-relay';
import { loadQuery, useRelayEnvironment } from 'react-relay';
import { useRouter } from 'react-resource-router';
import type { Column } from 'rest/requests-column-settings';
import * as grid from 'view/styles/grid';
import { sizes } from 'view/styles/sizes-viewport';
import { AnalyticsContext, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Profile } from '@atlaskit/atlassian-navigation';
import Avatar from '@atlaskit/avatar';
import Lozenge from '@atlaskit/lozenge';
import { CustomItem, HeadingItem, MenuGroup, Section } from '@atlaskit/menu';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import Popup from '@atlaskit/popup';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { colors, layers } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { Button, Link } from '@atlassian/help-center-common-component/analytics';
import { HtmlAnchor } from '@atlassian/help-center-common-component/analytics/html-anchor';
import { ChangeBoardingSingleSpotlight } from '@atlassian/help-center-common-component/change-boarding-single-spotlight';
import { initializeConnect } from '@atlassian/help-center-common-component/connect-fragment';
import {
    EngagementMessages,
    HELP_CENTERS_MANAGEMENT_BASE_PATH,
    EngagementMessagesTarget,
} from '@atlassian/help-center-common-component/constants';
import { EngagementCoordinationClient } from '@atlassian/help-center-common-component/engagement-coordination-client';
import {
    USER_MENU_ACTION_COMPONENT,
    USER_MENU_ACTION_SECTION_MODULE,
} from '@atlassian/help-center-common-component/forge-ui/constants';
import { ForgeModalContext } from '@atlassian/help-center-common-component/forge-ui/modal-context';
import { ForgeModalContextProvider } from '@atlassian/help-center-common-component/forge-ui/modal-context-provider';
import type { SetExtensionToDisplayType } from '@atlassian/help-center-common-component/forge-ui/types';
import { PremiumEnterpriseAdminsChangeBoarding } from '@atlassian/help-center-common-component/premium-enterprise-admins-changeboarding';
import { RenderNextAnimationFrame } from '@atlassian/help-center-common-component/render-next-animation-frame';
import { VaOnboardingSpotlight } from '@atlassian/help-center-common-component/va-reporting-onboarding';
import { getRelativeBasePath } from '@atlassian/help-center-common-util/history';
import { withDeferredWrmLoaded } from '@atlassian/help-center-common-util/wrm';
import { ExperienceSuccess, useUFOComponentExperience } from '@atlassian/ufo';
import { AccountDetails } from '../account-details';
import { MultiHcDropdown } from '../multi-hc-dropdown';
import { NUMBER_OF_HELP_CENTERS_PER_FETCH, SORT_ORDER } from '../multi-hc-dropdown/constants';
import MultiHcDropdownQuery from '../multi-hc-dropdown-content/__generated__/multiHcDropdownContentComponentQuery.graphql';
import type { multiHcDropdownContentComponentQuery } from '../multi-hc-dropdown-content/__generated__/multiHcDropdownContentComponentQuery.graphql';
import { UpgradeModal } from '../upgrade-modal';
import { AvatarMenuExperienceFailure, avatarMenuExperience } from './experiences';
import messages from './messages';
import { VirtualAgentMenu } from './virtual-agent-menu';

export interface CustomMenuLinkProps {
    id: string | undefined;
    key: string | undefined;
    label: string | undefined;
    href: string;
    styleClass: string | undefined;
}

interface Portal {
    id?: number;
    name?: string;
    isProjectSimplified: boolean;
    isProjectAdmin: boolean;
    canEditAnnouncement: boolean;
}
export interface StateProps {
    avatarSrc: string | undefined;
    displayName: string;
    email: string;
    customMenuLinks: CustomMenuLinkProps[];
    areCustomerServiceSettingsLinksAvailable: boolean;
    routeName?: string;
    portal?: Portal;
    pendingApprovalCount?: number;
    approvalCount?: number;
    canAdministerJIRA: boolean;
    canCustomiseHelpCenter?: boolean;
    myRequestCount?: number;
    defaultColumnSettings?: Column[];
    jsmAIConfigEnabled?: boolean;
    isOpen?: boolean;
    disableAvatarMenu?: boolean;
    hoistedProjectKey?: string;
    jiraDisplayUrl?: string;
}

export interface DispatchProps {
    onLogoutClick: () => LogOutAction;
    openHelpCenterCustomizationSidebar: () => void;
    openRequestListCustomizationSidebar: () => void;
}

export type Props = StateProps & DispatchProps;
let multiHcDropdownQueryReference: PreloadedQuery<multiHcDropdownContentComponentQuery> | null = null;

interface ForgeTitleProps {
    onClose: (actionSubjectId?: string) => void;
}

export const ForgeTitleMenuLinks = ({ onClose }: ForgeTitleProps) => {
    const {
        data: { extensionTitles },
        methods: { setExtensionToDisplay: onClick },
    } = React.useContext(ForgeModalContext);

    const forgeActionLinkOnClick = (id: string, onClickApp: SetExtensionToDisplayType) => () => {
        onClose('navBarForgeApp');
        return onClickApp(id);
    };
    return (
        <>
            {extensionTitles.map(({ id, title }) => {
                return (
                    <CustomItem
                        id={id}
                        key={`forge-title_${id}`}
                        component={CustomButton}
                        actionSubjectId="navBarForgeApp"
                        onClick={forgeActionLinkOnClick(id, onClick)}
                    >
                        {title}
                    </CustomItem>
                );
            })}
        </>
    );
};

const menuBottomSection = (
    onClose: (actionSubjectId?: string) => void,
    isHelpCenterManagementScreen: boolean,
    onLogoutClick: () => LogOutAction
) => {
    return (
        <Section hasSeparator>
            {isHelpCenterManagementScreen ? (
                <CustomItem
                    actionSubjectId="navbarProfile"
                    key={`bottom-menu-management-screen-navbarProfile`}
                    component={CustomAnchor}
                    href={'/servicedesk/customer/user/profile'}
                    onClick={() => onClose('navbarProfile')}
                    data-test-id="profile-link"
                >
                    <FormattedMessage {...messages.profile} />
                </CustomItem>
            ) : (
                <CustomItem
                    key={`bottom-menu-navbarProfile`}
                    component={CustomLink}
                    to={'/user/profile'}
                    actionSubjectId="navbarProfile"
                    onClick={() => onClose('navbarProfile')}
                    data-test-id="profile-link"
                >
                    <FormattedMessage {...messages.profile} />
                </CustomItem>
            )}
            <AnalyticsContext data={{ actionSubjectId: 'navbarLogout' }}>
                <CustomItem
                    component={CustomButton}
                    actionSubjectId="navbarLogout"
                    aria-label={'logout'}
                    onClick={onLogoutClick}
                    data-test-id="logout-link"
                >
                    <FormattedMessage {...messages.logout} />
                </CustomItem>
            </AnalyticsContext>
        </Section>
    );
};

const logoutLink = (onLogoutClick: () => LogOutAction) => {
    return (
        <Section hasSeparator>
            <AnalyticsContext data={{ actionSubjectId: 'navbarLogout' }}>
                <CustomItem
                    component={CustomButton}
                    actionSubjectId="navbarLogout"
                    aria-label={'logout'}
                    onClick={onLogoutClick}
                    data-test-id="logout-link"
                >
                    <FormattedMessage {...messages.logout} />
                </CustomItem>
            </AnalyticsContext>
        </Section>
    );
};

export const CustomMenuLinks = ({
    customMenuLinks,
    onClose,
}: {
    customMenuLinks: CustomMenuLinkProps[];
    onClose: (actionSubjectId?: string) => void;
}) => (
    <span data-test-id="custom-menu-link">
        {customMenuLinks.map((menuLink) => (
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            <div key={`custom-menu-link_${menuLink.key || ''}`} className={menuLink.styleClass}>
                <CustomItem
                    actionSubjectId={'custom-menu-link'}
                    component={CustomAnchor}
                    href={menuLink.href}
                    onClick={() => onClose('custom-menu-link')}
                    data-test-id="custom-menu-link"
                    key={`custom-menu-link-id_${menuLink.id || ''}`}
                >
                    {menuLink.label}
                </CustomItem>
            </div>
        ))}
    </span>
);

const buildCustomMenuLinks = (customMenuLinks: CustomMenuLinkProps[], onClose: (actionSubjectId?: string) => void) => {
    return customMenuLinks.map((menuLink) => (
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        <div key={`build-custom-menu-link_${menuLink.key || ''}`} className={menuLink.styleClass}>
            <CustomItem
                actionSubjectId={'custom-menu-link'}
                component={CustomAnchor}
                data-test-id="custom-menu-link"
                key={`custom-menu-link-id_${menuLink.id || ''}`}
                onClick={() => onClose('custom-menu-link')}
                href={menuLink.href}
            >
                {menuLink.label}
            </CustomItem>
        </div>
    ));
};

const TopMenu = (
    myRequestCount: number,
    onClose: (actionSubjectId?: string) => void,
    pendingApprovalCount: number,
    approvalCount: number,
    isHelpCenterManagementScreen: boolean
) => {
    di(isHelpCenterNewDropdownEnabled);
    const customizeMenu = [
        {
            onClick: () => onClose('navbarUserRequests'),
            link: isHelpCenterManagementScreen
                ? '/servicedesk/customer/user/requests?reporter=all&statuses=open'
                : '/user/requests?reporter=all&statuses=open',
            label: <FormattedMessage {...messages.requests} />,
            actionSubjectId: 'navbarUserRequests',
            iconAfter: myRequestCount > 0 ? <AvatarBadge>{myRequestCount}</AvatarBadge> : undefined,
            isVisible: true,
            dataTestId: 'requests-link',
        },
        {
            onClick: () => onClose('navbarUserApprovals'),
            link: isHelpCenterManagementScreen ? '/servicedesk/customer/user/approvals' : '/user/approvals',
            label: <FormattedMessage {...messages.approvals} />,
            actionSubjectId: 'navbarUserApprovals',
            iconAfter: pendingApprovalCount > 0 ? <AvatarBadge>{pendingApprovalCount}</AvatarBadge> : undefined,
            isVisible: approvalCount > 0 || pendingApprovalCount > 0,
            dataTestId: 'approvals-link',
        },
        {
            onClick: () => onClose('navbarProfile'),
            link: isHelpCenterManagementScreen ? '/servicedesk/customer/user/profile' : '/user/profile',
            label: <FormattedMessage {...messages.profile} />,
            actionSubjectId: 'navbarProfile',
            isVisible: !isHelpCenterNewDropdownEnabled(),
            dataTestId: 'profile-link',
        },
    ];
    return (
        <Section>
            {customizeMenu.map((menu) => {
                if (!menu.isVisible) {
                    return null;
                }
                return (
                    <>
                        {isHelpCenterManagementScreen ? (
                            <CustomItem
                                actionSubjectId={menu.actionSubjectId}
                                key={`top-menu-management-screen_${menu.actionSubjectId}`}
                                component={CustomAnchor}
                                href={menu.link}
                                onClick={menu.onClick}
                                iconAfter={menu.iconAfter}
                                data-test-id={menu.dataTestId}
                            >
                                {menu.label}
                            </CustomItem>
                        ) : (
                            <CustomItem
                                key={`top-menu_${menu.actionSubjectId}`}
                                component={CustomLink}
                                to={menu.link}
                                actionSubjectId={menu.actionSubjectId}
                                onClick={menu.onClick}
                                iconAfter={menu.iconAfter}
                                data-test-id={menu.dataTestId}
                            >
                                {menu.label}
                            </CustomItem>
                        )}
                    </>
                );
            })}
        </Section>
    );
};

interface CustomizeMenuItem {
    link?: string;
    label: ReactElement;
    customRenderer?: (props: { menu: CustomizeMenuItem }) => ReactElement;
    actionSubjectId: string;
    isVisible: boolean | undefined;
    onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
    testId?: string;
    isAnchor?: boolean;
    isBeta?: boolean;
    isNew?: boolean;
    showSpotlight?: boolean;
}

interface RenderMultiHcDropdownProps {
    setDescription: (description: string) => void;
    setTitle: (title: string) => void;
    setModalOpen: (open: boolean) => void;
    showMultiHCChangeBoardingSpotlight: boolean;
}

const RenderMultiHcDropdown = ({
    setDescription,
    setTitle,
    setModalOpen,
    showMultiHCChangeBoardingSpotlight,
}: RenderMultiHcDropdownProps) => {
    di(MultiHcDropdown);
    const { formatMessage } = useIntl();

    const onCreateHcClick = () => {
        setTitle(formatMessage(messages.onboardingTitleHCClick));
        setDescription(formatMessage(messages.onboardingDescriptionCreateOrHcClickWithLink));
        setModalOpen(true);
    };

    const onHelpCenterClick = () => {
        setTitle(formatMessage(messages.onboardingTitleHCClick));
        setDescription(formatMessage(messages.onboardingDescriptionCreateOrHcClickWithLink));
        setModalOpen(true);
    };

    return (
        <Section hasSeparator>
            <HeadingItem id="atlassian-navigation-hc-selection-dialog-heading">
                {formatMessage(messages.helpCenter)}
            </HeadingItem>
            <Box xcss={MultiHcDropdownContainer}>
                <MultiHcDropdown
                    onCreateHcClick={onCreateHcClick}
                    onHelpCenterClick={onHelpCenterClick}
                    showMultiHCChangeBoardingSpotlight={showMultiHCChangeBoardingSpotlight}
                    queryRef={multiHcDropdownQueryReference}
                />
            </Box>
        </Section>
    );
};

const CustomMenuItems = ({ menu }: { menu: CustomizeMenuItem }) => {
    const { formatMessage } = useIntl();
    return (
        <CustomItem
            component={CustomLink}
            to={menu.link}
            actionSubjectId={menu.actionSubjectId}
            onClick={menu.onClick}
            testId={menu.testId}
            iconAfter={
                <>
                    {menu.isBeta && <Lozenge appearance="new">{formatMessage(messages.beta)}</Lozenge>}
                    {menu.isNew && <Lozenge appearance="new">{formatMessage(messages.newLozenge)}</Lozenge>}
                </>
            }
        >
            {menu.label}
        </CustomItem>
    );
};

const EditPageLayoutWithPopUp = ({ menu }: { menu: CustomizeMenuItem }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { formatMessage } = useIntl();

    const contentCallback = useCallback(() => {
        return (
            <Box xcss={pageLayoutPremiumPopUp}>
                <Text>{formatMessage(messages.pageLayoutPremiumDescription)}</Text>
                <Link
                    actionSubjectId="flexibleHelpCenterTryLink"
                    href={getBillingUrl(['jira-servicedesk'], getCloudId())}
                    target={'_blank'}
                >
                    {formatMessage(messages.pageLayoutTryButton)}
                </Link>
            </Box>
        );
    }, [formatMessage]);
    const triggerCallback = useCallback(
        (triggerProps: TriggerProps) => {
            return (
                <Box
                    {...triggerProps}
                    onKeyDown={(event: KeyboardEvent) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                            setIsOpen((state) => !state);
                        }
                    }}
                    onClick={() => {
                        setIsOpen((state) => !state);
                    }}
                >
                    <CustomMenuItems
                        menu={{
                            ...menu,
                            onClick: (event) => {
                                event.preventDefault();
                            },
                        }}
                    />
                </Box>
            );
        },
        [menu]
    );
    return (
        <Popup
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            placement="left-start"
            content={contentCallback}
            trigger={triggerCallback}
        />
    );
};

const CustomizeLinks = (
    onClose: (actionSubjectId?: string) => void,
    openHelpCenterCustomizationSidebar: DispatchProps['openHelpCenterCustomizationSidebar'],
    canAdministerJIRA: boolean,
    routeName: string | undefined,
    basePath: string,
    areCustomerServiceSettingsLinksAvailable: boolean,
    jsmAIConfigEnabled?: boolean,
    hoistedProjectKey?: string,
    jiraDisplayUrl?: string
) => {
    di(
        isAdvancedCustomizationEnabled,
        getIsHCCustomizationEnabled,
        isAdvancedCustomizationEditPageEnabled,
        isUnifiedHelpReportingPageEnabled,
        isNewReportingExperienceEnabled,
        VaOnboardingSpotlight,
        EngagementCoordinationClient,
        isHelpCenterNewDropdownEnabled,
        RenderNextAnimationFrame,
        token
    );
    const { formatMessage } = useIntl();
    const [showVaReportingOnboardingSpotlight, setShowVaReportingOnboardingSpotlight] = useState(true);
    const isHomePage = routeName === 'help-center' || routeName === 'view-homepage';
    const isSmallScreen = window.innerWidth < sizes.xsmall;

    const customizeMenu: CustomizeMenuItem[] = [
        {
            link: `${basePath}?customize=true&experience=login-announcement`,
            label: <FormattedMessage {...messages.signIn} />,
            actionSubjectId: 'navbarLoginAnnouncement',
            isVisible: isHomePage && canAdministerJIRA && !isSmallScreen && !isHelpCenterNewDropdownEnabled(),
            onClick: () => onClick('navbarLoginAnnouncement'),
            testId: 'login-announcement-link',
        },
        {
            link: `${basePath}?customize=true&experience=help-center-announcement`,
            label: <FormattedMessage {...messages.announcement} />,
            actionSubjectId: 'navbarAnnouncement',
            isVisible: isHomePage && !isSmallScreen && !isHelpCenterNewDropdownEnabled(),
            onClick: () => onClick('navbarAnnouncement'),
            testId: 'announcement-link',
        },
        {
            link: `${basePath}?customize=true&experience=theme`,
            label: <FormattedMessage {...messages.look} />,
            actionSubjectId: 'navbarLookAndFeel',
            isVisible: isHomePage && canAdministerJIRA && !isHelpCenterNewDropdownEnabled(),
            onClick: () => onClick('navbarLookAndFeel'),
            testId: 'look-and-feel-link',
        },
        {
            link: '/settings/portal-reorder',
            label:
                isAdvancedCustomizationEnabled() && isAdvancedCustomizationEditPageEnabled() ? (
                    <FormattedMessage {...messages.topicsAndPortals} />
                ) : (
                    <FormattedMessage {...messages.pageLayout} />
                ),
            actionSubjectId: 'navbarPortalReorder',
            isVisible: isHomePage && canAdministerJIRA && !isSmallScreen && !isHelpCenterNewDropdownEnabled(),
            onClick: () => onClick('navbarPortalReorder'),
            testId: 'topics-link',
        },
        {
            link: '/edit',
            label: <FormattedMessage {...messages.pageLayout} />,
            actionSubjectId: 'navbarHideAndReorder',
            isVisible:
                isAdvancedCustomizationEnabled() &&
                isAdvancedCustomizationEditPageEnabled() &&
                isHomePage &&
                canAdministerJIRA &&
                !isSmallScreen &&
                !isHelpCenterNewDropdownEnabled(),
            onClick: () => onClick('navbarHideAndReorder'),
            testId: 'hide-and-reorder-link',
            customRenderer: getIsHCCustomizationEnabled() ? undefined : EditPageLayoutWithPopUp,
            isBeta: true,
        },
        {
            link: '/reporting',
            label: isNewReportingExperienceEnabled() ? (
                <FormattedMessage {...messages.virtualAgent} />
            ) : (
                <FormattedMessage {...messages.reporting} />
            ),
            actionSubjectId: 'navBarReporting',
            isVisible:
                !isVirtualAgentAdminToggleEnabled() &&
                isUnifiedHelpReportingPageEnabled() &&
                isHomePage &&
                canAdministerJIRA &&
                jsmAIConfigEnabled &&
                !isSmallScreen,
            onClick: () => onClick('navBarReporting'),
            isNew: isNewReportingExperienceEnabled(),
            showSpotlight: isNewReportingExperienceEnabled(),
            testId: 'reporting-link',
        },
    ];

    if (isJsmCsMasterFlagEnabled()) {
        const isVisible =
            areCustomerServiceSettingsLinksAvailable &&
            !!jiraDisplayUrl &&
            !!hoistedProjectKey &&
            isHomePage &&
            canAdministerJIRA;
        const additionalMenuItems = [
            {
                isVisible,
                isAnchor: true,
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                link: `${jiraDisplayUrl}/jira/helpcenter/projects/${hoistedProjectKey}/settings?referrer=${encodeURI(
                    window.location.href
                )}`,
                label: <FormattedMessage {...messages.helpCenterSettings} />,
                actionSubjectId: 'helpCenterSettings',
                testId: 'help-center-settings',
            },
        ];
        customizeMenu.push(...additionalMenuItems);
    }

    const onClick = (actionSubjectId: string) => {
        onClose(actionSubjectId);
        openHelpCenterCustomizationSidebar();
    };
    return (
        <Section>
            {customizeMenu.map((menu) => {
                if (!menu.isVisible) {
                    return null;
                }
                if (menu.isAnchor) {
                    return (
                        <CustomItem
                            key={`customize-menu_${menu.actionSubjectId}`}
                            component={CustomAnchor}
                            href={menu.link}
                            actionSubjectId={menu.actionSubjectId}
                            onClick={menu.onClick}
                            testId={menu.testId}
                            iconAfter={
                                menu.isBeta && <Lozenge appearance="new">{formatMessage(messages.beta)}</Lozenge>
                            }
                        >
                            {menu.label}
                        </CustomItem>
                    );
                }

                if (menu.showSpotlight) {
                    return (
                        <>
                            <RenderNextAnimationFrame>
                                {() => (
                                    <SpotlightTarget
                                        name={EngagementMessagesTarget.REPORTING_ONBOARDING_SPOTLIGHT}
                                        key={menu.actionSubjectId}
                                    >
                                        <CustomItem
                                            key={`customize-menu_${menu.actionSubjectId}`}
                                            component={CustomLink}
                                            to={menu.link}
                                            actionSubjectId={menu.actionSubjectId}
                                            onClick={menu.onClick}
                                            testId={menu.testId}
                                            iconAfter={
                                                <>
                                                    {menu.isBeta && (
                                                        <Lozenge appearance="new">
                                                            {formatMessage(messages.beta)}
                                                        </Lozenge>
                                                    )}
                                                    {menu.isNew && (
                                                        <Lozenge appearance="new">
                                                            {formatMessage(messages.newLozenge)}
                                                        </Lozenge>
                                                    )}
                                                </>
                                            }
                                        >
                                            {menu.label}
                                        </CustomItem>
                                    </SpotlightTarget>
                                )}
                            </RenderNextAnimationFrame>
                            {/* Remove this when isNewReportingExperienceEnabled FG is cleaned up */}
                            {showVaReportingOnboardingSpotlight && (
                                <SpotlightTransition>
                                    <EngagementCoordinationClient
                                        messageId={EngagementMessages.ONBOARD_VA_REPORTING}
                                        key={EngagementMessagesTarget.REPORTING_ONBOARDING_SPOTLIGHT}
                                    >
                                        <VaOnboardingSpotlight
                                            setShowVaReportingOnboardingSpotlight={
                                                setShowVaReportingOnboardingSpotlight
                                            }
                                        />
                                    </EngagementCoordinationClient>
                                </SpotlightTransition>
                            )}
                        </>
                    );
                }
                if (menu.customRenderer) {
                    return <menu.customRenderer key={`customize-menu_${menu.actionSubjectId}`} menu={menu} />;
                }
                return (
                    <CustomItem
                        key={`customize-menu_${menu.actionSubjectId}`}
                        component={CustomLink}
                        to={menu.link}
                        actionSubjectId={menu.actionSubjectId}
                        onClick={menu.onClick}
                        testId={menu.testId}
                        iconAfter={
                            <>
                                {menu.isBeta && <Lozenge appearance="new">{formatMessage(messages.beta)}</Lozenge>}
                                {menu.isNew && <Lozenge appearance="new">{formatMessage(messages.newLozenge)}</Lozenge>}
                            </>
                        }
                    >
                        {menu.label}
                    </CustomItem>
                );
            })}
        </Section>
    );
};

interface RenderHCSectionProps {
    setDescription: (description: string) => void;
    setTitle: (title: string) => void;
    setModalOpen: (open: boolean) => void;
    onClick: (actionSubjectId?: string) => void;
}

const RenderHCSection = ({ setTitle, setDescription, setModalOpen, onClick }: RenderHCSectionProps) => {
    const { formatMessage } = useIntl();

    const customizeMenu: CustomizeMenuItem[] = [
        {
            link: `${HELP_CENTERS_MANAGEMENT_BASE_PATH}`,
            label: <FormattedMessage {...messages.manageHc} />,
            actionSubjectId: 'manageHelpCenter',
            isVisible: true,
            onClick: () => onClick('manageHelpCenter'),
            testId: 'manage-hc-link',
        },
        {
            link: getIsMultiHCEnabled() ? `${HELP_CENTERS_MANAGEMENT_BASE_PATH}?create=true` : '#',
            label: <FormattedMessage {...messages.createHc} />,
            actionSubjectId: 'createHelpCenter',
            isVisible: true,
            onClick: getIsMultiHCEnabled()
                ? () => onClick('createHelpCenter')
                : () => {
                      onClick('createHelpCenter');
                      setTitle(formatMessage(messages.onboardingTitleHCClick));
                      setDescription(formatMessage(messages.onboardingDescriptionCreateOrHcClickWithLink));
                      setModalOpen(true);
                  },
            testId: 'create-hc-link',
        },
    ];

    return (
        <Section hasSeparator>
            <HeadingItem id="avatar-menu-hc-section-heading">{formatMessage(messages.helpCenter)}</HeadingItem>
            {customizeMenu.map((menu) => {
                return <CustomMenuItems key={`hc-section-menu_${menu.actionSubjectId}`} menu={menu} />;
            })}
        </Section>
    );
};

const PortalLinks = ({
    onClose,
    canCustomiseHelpCenter,
    portal,
    jsmAIConfigEnabled,
    canAdministerJIRA,
}: {
    onClose: (actionSubjectId?: string) => void;
    canCustomiseHelpCenter: boolean | undefined;
    portal: Portal | undefined;
    jsmAIConfigEnabled?: boolean;
    canAdministerJIRA: boolean;
}) => {
    const { formatMessage } = useIntl();
    if (!portal) {
        return null;
    }
    const { isProjectSimplified, isProjectAdmin, canEditAnnouncement } = portal;
    const isSmallScreen = window.innerWidth < sizes.xsmall;
    const shouldRenderAsPortalAnnouncement = (): boolean => {
        // for simplified projects, render side bar as announcement for agents with permission
        if (isProjectSimplified && !isProjectAdmin && canEditAnnouncement) {
            return true;
        }
        // in classic projects render sidebar as announcement for users who can't modify helpcenter, but can edit announcements
        if (!isProjectSimplified && !canCustomiseHelpCenter && canEditAnnouncement) {
            return true;
        }
        return false;
    };

    const customizeMenu = [
        {
            link: '?customize=true&experience=add-announcement',
            onClick: () => onClose('navbarPortalAnnouncement'),
            label: <FormattedMessage {...messages.portalAnnouncement} />,
            actionSubjectId: 'navbarPortalAnnouncement',
            isVisible: canEditAnnouncement || shouldRenderAsPortalAnnouncement(),
        },
        {
            link: '?customize=true&experience=request-groups',
            onClick: () => onClose('navbarRequestTypeGroup'),
            label: <FormattedMessage {...messages.portalRequestTypeGroup} />,
            actionSubjectId: 'navbarRequestTypeGroup',
            isVisible: isProjectAdmin && isProjectSimplified && !shouldRenderAsPortalAnnouncement(),
        },
        {
            link: '/reporting',
            label: isNewReportingExperienceEnabled() ? (
                <FormattedMessage {...messages.virtualAgent} />
            ) : (
                <FormattedMessage {...messages.reporting} />
            ),
            actionSubjectId: 'navBarReporting',
            isVisible:
                !isVirtualAgentAdminToggleEnabled() &&
                isUnifiedHelpReportingPageEnabled() &&
                jsmAIConfigEnabled &&
                !isSmallScreen &&
                canAdministerJIRA,
            onClick: () => onClose('navBarReporting'),
            isNew: isNewReportingExperienceEnabled() ? true : false,
        },
    ];

    const showHeading = customizeMenu.some((menu) => menu.isVisible);
    return (
        <Section>
            {showHeading && <HeadingItem>{formatMessage(messages.portal)}</HeadingItem>}
            {customizeMenu.map((menu) => {
                if (!menu.isVisible) {
                    return null;
                }
                return (
                    <CustomItem
                        key={`portal-link_${menu.actionSubjectId}`}
                        component={CustomLink}
                        to={menu.link}
                        actionSubjectId={menu.actionSubjectId}
                        onClick={menu.onClick}
                        iconAfter={
                            menu.isNew && <Lozenge appearance="new">{formatMessage(messages.newLozenge)}</Lozenge>
                        }
                    >
                        {menu.label}
                    </CustomItem>
                );
            })}
        </Section>
    );
};

const RequestList = (
    onClose: (actionSubjectId?: string) => void,
    openRequestListCustomizationSidebar: DispatchProps['openRequestListCustomizationSidebar']
) => {
    const onClick = (actionSubjectId: string) => {
        onClose(actionSubjectId);
        openRequestListCustomizationSidebar();
    };
    const { formatMessage } = useIntl();
    return (
        <Section>
            <HeadingItem>{formatMessage(messages.requestListHeading)}</HeadingItem>
            <CustomItem
                component={CustomButton}
                actionSubjectId="navbarUserRequestListCustomize"
                aria-label={formatMessage(messages.customizeRequestList)}
                onClick={() => onClick('navbarUserRequestListCustomize')}
                data-test-id="requests-list-page-link"
            >
                <FormattedMessage {...messages.customizeRequestList} />
            </CustomItem>
        </Section>
    );
};

const UserProfileConnectActionLinks = withDeferredWrmLoaded(CustomMenuLinks, initializeConnect);

const AvatarMenuDumbV2 = (props: Props) => {
    di(
        ForgeModalContextProvider,
        ForgeActionModule,
        useAnalyticsEvents,
        getRelativeBasePath,
        isAdvancedHelpCenter,
        useUFOComponentExperience,
        ExperienceSuccess,
        useQueryParams,
        EngagementCoordinationClient,
        isChangeBoardingForJSMAdminsFreeAndStandardEditionEnabled,
        isChangeBoardingForJSMAdminsSinglePortalEnabled,
        isChangeBoardingForJSMAdminsPremiumAndEnterpriseEditionEnabled,
        MultiHcDropdown,
        PremiumEnterpriseAdminsChangeBoarding,
        useRouter,
        isUnifiedHelpReportingPageEnabled,
        isHelpCenterNewDropdownEnabled
    );

    // Reading the from useRouter even thought we are getting from props because
    // there is an unneccessary rerender happening in the component which is causing the Engagement client
    // to make an unnecessary start call
    const [{ route }] = useRouter();
    const { name: currentRouteName } = route;
    const isHomepage = props?.routeName === 'help-center' || props?.routeName === 'view-homepage';
    useUFOComponentExperience(avatarMenuExperience);
    const isHelpCenterManagementScreen = getRelativeBasePath() === HELP_CENTERS_MANAGEMENT_BASE_PATH;
    const basePath = isAdvancedHelpCenter() ? '/' : '/portals';
    const [isOpen, setIsOpen] = useState(!!props.isOpen);
    const [showMultiHCChangeBoardingSpotlight, setShowMultiHCChangeBoardingSpotlight] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { customize, experience } = useQueryParams();
    const { formatMessage } = useIntl();
    const prevSpotlightRef = useRef<boolean>(false);
    const isSmallScreen = window.innerWidth < sizes.xsmall;

    useEffect(() => {
        // if customize is true and experience is not set, open the sidebar
        if (customize === 'true' && !experience) {
            setIsOpen(true);
        }

        if (showMultiHCChangeBoardingSpotlight) {
            setIsOpen(true);
            prevSpotlightRef.current = true;
        }

        if (!showMultiHCChangeBoardingSpotlight && prevSpotlightRef.current) {
            setIsOpen(false);
            prevSpotlightRef.current = false;
        }
    }, [customize, experience, showMultiHCChangeBoardingSpotlight]);

    const env = useRelayEnvironment();
    useEffect(() => {
        if (
            props.canAdministerJIRA &&
            !isSmallScreen &&
            !isHelpCenterManagementScreen &&
            !isHelpCenterNewDropdownEnabled()
        ) {
            multiHcDropdownQueryReference = loadQuery<multiHcDropdownContentComponentQuery>(
                env,
                MultiHcDropdownQuery,
                {
                    SORT_ORDER,
                    NUMBER_OF_HELP_CENTERS_PER_FETCH,
                    cloudId: getCloudId(),
                    workspaceAri: getWorkspaceAri(),
                },
                { fetchPolicy: 'store-or-network' }
            );
        }
    }, [env, isHelpCenterManagementScreen, isSmallScreen, props.canAdministerJIRA]);

    const onOpenChange = useCallback(
        (open: boolean) => {
            if (showMultiHCChangeBoardingSpotlight) return;

            setIsOpen(open);
            // if customize is true and experience is not set, clear customize from the queryParam
            if (!open && customize === 'true' && !experience) {
                clearSidebarExperienceAndCustomize();
            }
        },
        [customize, experience, showMultiHCChangeBoardingSpotlight]
    );

    const { createAnalyticsEvent } = useAnalyticsEvents();
    const onItemClick = (actionSubjectId?: string) => {
        if (actionSubjectId && createAnalyticsEvent) {
            createAnalyticsEvent({
                actionSubjectId,
                action: 'clicked',
                actionSubject: 'avatarMenu',
                version: '2',
                analyticsType: UI_EVENT_TYPE,
            }).fire();
        }
        setIsOpen(false);
    };

    const [openModal, setModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const content = () => {
        const {
            myRequestCount,
            customMenuLinks,
            onLogoutClick,
            openHelpCenterCustomizationSidebar,
            openRequestListCustomizationSidebar,
            routeName,
            pendingApprovalCount,
            approvalCount,
            canAdministerJIRA,
            canCustomiseHelpCenter,
            defaultColumnSettings,
            portal,
            jsmAIConfigEnabled,
            avatarSrc,
            displayName,
            email,
            hoistedProjectKey,
            areCustomerServiceSettingsLinksAvailable,
            jiraDisplayUrl,
        } = props;

        return (
            <Wrapper aria-label={formatMessage(messages.tooltip)} data-testid="avatar-menu-content">
                <MenuGroup maxHeight={maxHeight} spacing={'cozy'}>
                    {<AccountDetails avatarSrc={avatarSrc} displayName={displayName} email={email} />}
                    {TopMenu(
                        myRequestCount || 0,
                        onItemClick,
                        pendingApprovalCount || 0,
                        approvalCount || 0,
                        isHelpCenterManagementScreen
                    )}
                    {!isHelpCenterManagementScreen && (
                        <>
                            {!isHelpCenterNewDropdownEnabled() && !isSmallScreen && canAdministerJIRA && (
                                <RenderMultiHcDropdown
                                    setTitle={setTitle}
                                    setDescription={setDescription}
                                    setModalOpen={setModalOpen}
                                    showMultiHCChangeBoardingSpotlight={showMultiHCChangeBoardingSpotlight}
                                />
                            )}
                            {isHelpCenterNewDropdownEnabled() && !isSmallScreen && canAdministerJIRA && (
                                <RenderHCSection
                                    setTitle={setTitle}
                                    setDescription={setDescription}
                                    setModalOpen={setModalOpen}
                                    onClick={onItemClick}
                                />
                            )}
                            {canCustomiseHelpCenter &&
                                isHomepage &&
                                CustomizeLinks(
                                    onItemClick,
                                    openHelpCenterCustomizationSidebar,
                                    canAdministerJIRA,
                                    routeName,
                                    basePath,
                                    areCustomerServiceSettingsLinksAvailable,
                                    jsmAIConfigEnabled,
                                    hoistedProjectKey,
                                    jiraDisplayUrl
                                )}
                            {isVirtualAgentAdminToggleEnabled() &&
                                jsmAIConfigEnabled &&
                                !isSmallScreen &&
                                canAdministerJIRA && <VirtualAgentMenu onClose={onItemClick} component={CustomLink} />}
                            {(routeName === 'portal-home' || routeName === 'request-create') &&
                                !isHelpCenterNewDropdownEnabled() && (
                                    <PortalLinks
                                        onClose={onItemClick}
                                        canCustomiseHelpCenter={canCustomiseHelpCenter}
                                        portal={portal}
                                        jsmAIConfigEnabled={jsmAIConfigEnabled}
                                        canAdministerJIRA={canAdministerJIRA}
                                    />
                                )}
                            {routeName === 'requests-list' &&
                                canAdministerJIRA &&
                                Boolean(defaultColumnSettings?.length) &&
                                !isHelpCenterNewDropdownEnabled() &&
                                RequestList(onItemClick, openRequestListCustomizationSidebar)}
                            {customMenuLinks && customMenuLinks.length > 0 ? (
                                <Section hasSeparator={customMenuLinks && customMenuLinks.length > 0}>
                                    {isWrmOptimizationsEnabled() ? (
                                        <UserProfileConnectActionLinks
                                            customMenuLinks={customMenuLinks}
                                            onClose={onItemClick}
                                        />
                                    ) : (
                                        buildCustomMenuLinks(customMenuLinks, onItemClick)
                                    )}
                                </Section>
                            ) : undefined}
                            <ForgeTitleMenuLinks onClose={onItemClick} />
                        </>
                    )}
                    {isHelpCenterNewDropdownEnabled() &&
                        menuBottomSection(onItemClick, isHelpCenterManagementScreen, onLogoutClick)}
                    {!isHelpCenterNewDropdownEnabled() && logoutLink(onLogoutClick)}
                </MenuGroup>
                {/* Fire success if user data has loaded this should give better TTI and FMP numbers */}
                {displayName && <ExperienceSuccess experience={avatarMenuExperience} />}
            </Wrapper>
        );
    };

    const handleUpgradeModalNext = useCallback(() => {
        setModalOpen(false);
        window.open(getBillingUrl(['jira-servicedesk'], getCloudId()), '_blank');
    }, []);

    const Trigger = useCallback(
        (triggerProps: Partial<TriggerProps>) => {
            const { avatarSrc, disableAvatarMenu = false, pendingApprovalCount = 0, myRequestCount = 0 } = props;
            const totalRequests = pendingApprovalCount + myRequestCount;
            return (
                <>
                    {totalRequests > 0 && <StyledAvatarBadge>{totalRequests}</StyledAvatarBadge>}
                    <ProfileContainer disabled={disableAvatarMenu} role="list">
                        <SpotlightTarget name={EngagementMessagesTarget.CHANGE_BOARDING_SPOTLIGHT}>
                            <Profile
                                {...triggerProps}
                                isDisabled={disableAvatarMenu}
                                icon={<Avatar src={avatarSrc} borderColor={token('color.border.inverse', colors.N0)} />}
                                isSelected={isOpen}
                                onClick={() => {
                                    onOpenChange(!isOpen);
                                }}
                                aria-label={formatMessage(messages.tooltip)}
                                tooltip={formatMessage(messages.tooltip)}
                                testId="user-avatar-container-2"
                            />
                        </SpotlightTarget>
                        {!isSmallScreen && (
                            <>
                                {(currentRouteName === 'help-center' || currentRouteName === 'view-homepage') &&
                                    isChangeBoardingForJSMAdminsFreeAndStandardEditionEnabled() && (
                                        <EngagementCoordinationClient
                                            messageId={EngagementMessages.CHANGE_BOARD_ADMINS_FREE_AND_STANDARD_EDITION}
                                        >
                                            <ChangeBoardingSingleSpotlight
                                                target={EngagementMessagesTarget.CHANGE_BOARDING_SPOTLIGHT}
                                                analyticsSubject="freeStandardAdminsChangeBoarding"
                                                messageId={
                                                    EngagementMessages.CHANGE_BOARD_ADMINS_FREE_AND_STANDARD_EDITION
                                                }
                                                headingText={formatMessage(
                                                    messages.freeStandardAdminsChangeBoardingHeading
                                                )}
                                                contentText={formatMessage(
                                                    messages.freeStandardAdminsChangeBoardingContent
                                                )}
                                                buttonText={formatMessage(
                                                    messages.freeStandardAdminsChangeBoardingButton
                                                )}
                                            />
                                        </EngagementCoordinationClient>
                                    )}
                                {currentRouteName === 'portal-home' &&
                                    isChangeBoardingForJSMAdminsSinglePortalEnabled() && (
                                        <EngagementCoordinationClient
                                            messageId={EngagementMessages.CHANGE_BOARD_ADMINS_SINGLE_PORTAL_SCREEN}
                                        >
                                            <ChangeBoardingSingleSpotlight
                                                target={EngagementMessagesTarget.CHANGE_BOARDING_SPOTLIGHT}
                                                analyticsSubject="singlePortalScreenAdminsChangeBoarding"
                                                messageId={EngagementMessages.CHANGE_BOARD_ADMINS_SINGLE_PORTAL_SCREEN}
                                                headingText={formatMessage(
                                                    messages.singlePortalScreenAdminsChangeBoardingHeading
                                                )}
                                                contentText={formatMessage(
                                                    messages.singlePortalScreenAdminsChangeBoardingContent
                                                )}
                                                buttonText={formatMessage(
                                                    messages.singlePortalScreenAdminsChangeBoardingButton
                                                )}
                                            />
                                        </EngagementCoordinationClient>
                                    )}
                                {(currentRouteName === 'help-center' || currentRouteName === 'view-homepage') &&
                                    isChangeBoardingForJSMAdminsPremiumAndEnterpriseEditionEnabled() && (
                                        <EngagementCoordinationClient
                                            messageId={EngagementMessages.CHANGE_BOARD_ADMINS_PREMIUM_ENTERPRISE}
                                        >
                                            <PremiumEnterpriseAdminsChangeBoarding
                                                setShowMultiHCChangeBoardingSpotlight={
                                                    setShowMultiHCChangeBoardingSpotlight
                                                }
                                            />
                                        </EngagementCoordinationClient>
                                    )}
                            </>
                        )}
                    </ProfileContainer>
                </>
            );
        },
        [props, isOpen, formatMessage, isSmallScreen, currentRouteName, onOpenChange]
    );

    const renderDropdownMenu = () => {
        return (
            <SpotlightManager blanketIsTinted>
                <Popup
                    isOpen={isOpen}
                    onClose={() => onOpenChange(false)}
                    placement="bottom-end"
                    zIndex={layers.modal()}
                    content={content}
                    trigger={Trigger}
                    // This is required to close the open menu when clicking outside of the menu
                    // A complex use case hence attaching a video of the same https://www.loom.com/share/95aa9f0112dd4e028194397e8dfeabea?sid=ef970902-843d-4b62-a578-3ec0f1f1b273
                    // shouldUseCaptureOnOutsideClick
                    key={'avatar-menu-dumb-v2-popup'}
                />
            </SpotlightManager>
        );
    };

    const renderError = useCallback((error: Error) => {
        return <AvatarMenuExperienceFailure message={error.message} />;
    }, []);

    return (
        <ErrorBoundary onError={renderError}>
            <AvatarMenuContainer>
                <ForgeModalContextProvider type={USER_MENU_ACTION_SECTION_MODULE}>
                    {renderDropdownMenu()}
                    <ForgeActionModule
                        type={USER_MENU_ACTION_SECTION_MODULE}
                        componentName={USER_MENU_ACTION_COMPONENT}
                    />
                </ForgeModalContextProvider>
            </AvatarMenuContainer>
            <ModalTransition>
                {openModal && (
                    <UpgradeModal
                        onNext={handleUpgradeModalNext}
                        onClose={() => setModalOpen(false)}
                        title={title}
                        description={description}
                    />
                )}
            </ModalTransition>
        </ErrorBoundary>
    );
};

AvatarMenuDumbV2.defaultProps = {
    customMenuLinks: [],
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const AvatarMenuContainer = styled.div({
    paddingRight: token('space.100', '8px'),
    position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const NoUnderlineLink = styled(Link)({
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
        textDecoration: 'none',
    },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Wrapper = styled.div({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    minWidth: grid.multiple(29.25).px,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    maxWidth: grid.multiple(100).px,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: token('color.text', colors.N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const StyledAvatarBadge = styled(AvatarBadge)({
    position: 'absolute',
    left: 0,
    bottom: 0,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    zIndex: layers.navigation(),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CustomLink = styled(NoUnderlineLink)({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: 'unset !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CustomButton = styled(Button)({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: `${token('color.text', colors.N800)} !important`,
    '&:focus, &:hover, &:visited, &:link, &:active': {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        color: `${token('color.text', colors.N800)} !important`,
    },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CustomAnchor = styled(HtmlAnchor)({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: 'unset !important',
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
        textDecoration: 'none',
    },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ProfileContainer = styled.div({
    // base on disabled prop update pointerEvents
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    pointerEvents: ({ disabled }: { disabled: boolean }) => (disabled ? 'none' : 'auto'),
});

const maxHeight = `calc(100vh - ${grid.multiple(24).px}`;

const MultiHcDropdownContainer = xcss({
    paddingBottom: 'space.100',
    paddingLeft: 'space.150',
    paddingRight: 'space.250',
    paddingTop: 'space.100',
});

const pageLayoutPremiumPopUp = xcss({
    padding: 'space.200',
    maxWidth: '256px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 'border.radius.300',
});

export default AvatarMenuDumbV2;
